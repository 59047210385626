@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table {
  /* min-w-full bg-white border-gray-100*/
  @apply min-w-full bg-white border-gray-100;
}
table thead tr {
  @apply bg-gray-200;
}
table thead tr th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase;
}
table tbody tr {
  @apply border-b border-gray-200;
}
table tbody tr td {
  @apply px-6 py-4 text-sm text-gray-900;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
    display: block;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    display: block;
  }
}

.slide-in {
  animation: slideInFromLeft 0.5s ease-in-out forwards;
}
